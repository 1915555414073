//
//
//
//

/* eslint-disable import/no-unresolved */
import HomeView from '@/components/PageView/HomeView';

export default {
  name: 'Home',
  middleware: ['load-data', 'isAffiliated', 'getDiscount'],
  components: {
    HomeView,
  },
  head() {
    return {
      title: 'Home',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'The latest and most advanced tools for all Motion Graphics designers and animators.',
        },

        {
          hid: 'keywords',
          name: 'keywords',
          keywords:
            'motion routes, after effects, after effects templates, after effects expressions, after effects plugins, animation software, free after effects expressions, free after effects expressions, free after effects templates',
        },
      ],
    };
  },
};
